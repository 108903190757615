.signature-component {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sigCanvasWrapper {
    border: 2px solid black; /* Make the border darker */
    width: 500px;
    height: 200px;
  }
  
  .sigCanvas {
    width: 100%;
    height: 100%;
  }
  
  .buttons {
    display: flex;
    gap: 10px; /* Creates some space between the buttons */
    margin-top: 20px;
  }
  
  .signatureImage {
    display: block;
    margin: 20px auto;
    border: 1px solid black;
    width: 150px;
  }
  